import { getuserinfo, logoutName } from './loginApi'
import Cookies from 'js-cookie'
import UserInfoGlobal from './UserInfoGlobal'
export function setToken (token) {
  return new Promise((resolve) => {
    if (token === null || token === 'null' || token === undefined) {
      resolve()
    }
    const loctoken = getToken()
    const isLogin = Cookies.get('isLogin')
    if ((isLogin !== undefined) && (isLogin === 'true')) {
      if (loctoken === token) {
        resolve()
      }
    }
    const keeplogin = getKeeplogin()
    if (keeplogin === 0) {
      Cookies.set('token', token, { domain: window.SITE_CONFIG.domain })
    } else {
      Cookies.set('token', token, { expires: 30, domain: window.SITE_CONFIG.domain })
    }
    Cookies.set('isLogin', 'true', { domain: window.SITE_CONFIG.domain })
    getUserInfoFromDB().then(() => {
      resolve()
    })
  })
}
export function setKeeplogin (keeplogin) {
  Cookies.set('keeplogin', keeplogin, { expires: 30, domain: window.SITE_CONFIG.domain })
}
export function getKeeplogin () {
  let keeplogin = Cookies.get('keeplogin')
  if (keeplogin === undefined) {
    keeplogin = 0
  }
  return keeplogin
}
export function getUserInfoFromDB () {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('source', window.SITE_CONFIG.source)
    getuserinfo(formData).then((res) => {
      if (res.data !== undefined && res.code === 0) {
        Cookies.set('isLogin', 'true', { domain: window.SITE_CONFIG.domain })
        UserInfoGlobal.setUseinfo(res.data)
        Cookies.set('userinfo', JSON.stringify(UserInfoGlobal), { domain: window.SITE_CONFIG.domain })
        resolve()
      }
    }).catch(err => {
      reject(err)
    })
  })
}
export function getToken () {
  let token = Cookies.get('token')
  if (token === null || token === '' || token === undefined) {
    token = ''
  }
  return token
}
export function getUserData (GLOBAL) {
  return new Promise((resolve, reject) => {
    const loctoken = getToken()
    const isLogin = Cookies.get('isLogin')
    if ((isLogin === undefined) && loctoken !== '') {
      // 获取用户信息
      getUserInfoFromDB().then(() => {
        resolve(setUserDataGLOBAL(GLOBAL))
      })
    } else {
      resolve(setUserDataGLOBAL(GLOBAL))
    }
  })
}
function setUserDataGLOBAL (GLOBAL) {
  if (Cookies.get('userinfo')) {
    const userinfo = JSON.parse(Cookies.get('userinfo'))
    if (userinfo !== null) {
      GLOBAL.setUseinfCookies(userinfo)
      return GLOBAL
    }
  }
  // clearToken()
  GLOBAL.clearUserinfo()
  return GLOBAL
}
export function logout (userInfoGlobal) {
  return new Promise((resolve) => {
    const token = getToken()
    if (token === null || token === '' || token === undefined) {
      resolve()
    }
    const formData = new FormData()
    logoutName(formData).then(() => {
      clearToken()
      if (!userInfoGlobal) {
        userInfoGlobal.clearUserinfo()
      }
      resolve()
    }).catch(() => {
      clearToken()
      if (userInfoGlobal) {
        userInfoGlobal.clearUserinfo()
      }
      resolve()
    })
  })
}
// 清空所有cookie 退出登录
function clearToken () {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
  }
  if (cookies.length > 0) {
    for (let j = 0; j < cookies.length; j++) {
      const cookie = cookies[j]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      const domain = location.host.substr(location.host.indexOf('.'))
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + domain
    }
  }
}
const userinfoservice = {
  logout,
  getToken,
  setToken,
  getUserData,
  getKeeplogin,
  getUserInfoFromDB,
  setKeeplogin
}
export default userinfoservice
