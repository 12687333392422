<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view/>
    </el-config-provider>
  </div>
</template>
<script setup>
import { ref, inject, reactive, onMounted, computed } from 'vue'
import userInfoService from '@/utils/userinfoservice'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import Cookies from 'js-cookie'

const language = ref(window.SITE_CONFIG.defaultLanguage) // 获取默认语言
const locale = computed(() => (language.value === 'CN' ? zhCn : en)) // 配置element的默认语言
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const state = reactive({
  userInfoGlobal
})
onMounted(() => {
  // 浏览器窗口可见性监听: visibilityState=visible 可见；visibilityState=hidden 不可见
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      inituserInfoGlobal()
      // console.log('用户信息', state.userInfoGlobal)
    }
  })
  txAdvertisement()
})
const inituserInfoGlobal = async () => {
  state.userInfoGlobal = await userInfoService.getUserData(state.userInfoGlobal)
}
/**
 * 腾讯广告相关
 */
const txAdvertisement = async () => {
  const url = window.location.href
  const params = new URLSearchParams(url.split('?')[1])

  try {
    let impressionid = ''
    if (params.get('gdt_vid')) {
      impressionid = params.get('gdt_vid')
      console.log('gdt_vid')
    } else {
      if (params.get('qz_gdt')) {
        impressionid = params.get('qz_gdt')
        console.log('qz_gdt')
      } else {
        impressionid = params.get('wx_traceid').split('#/')[0]
        console.log('wx traceid')
      }
    }
    Cookies.set('impressionid', impressionid, { domain: window.SITE_CONFIG.domain, sameSite: 'none', secure: true })
  } catch (e) {

  }

  // 如果存在click_id，则表示点击了广告
  // if (params.click_id) {
  //   Cookies.set('click_id', result.click_id)
  //   // await saveAds(result)s
  //   /**
  //    * 此处上传到服务器，用于统计广告点击量
  //    */
  // }
}
</script>
<style>
#app {
  font-family: Source Han Sans CN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  /* background: #f5faff !important; */
  background: #fff !important;
}
</style>
